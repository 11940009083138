<template>
  <div class="text-center">
    <v-dialog :value="value" @input="$emit('valueChanged', $event)" width="410">
      <v-card>
        <v-card-title class="font-16 fw-600 greyColor--text logout-title">
          {{ title }}
        </v-card-title>
        <v-divider></v-divider>

        <v-card-text class="font-12 fw-500 secondary--text mt-3">
          {{ description }}
        </v-card-text>

        <v-card-actions class="pb-7">
          <v-spacer></v-spacer>
          <v-btn
            class="text-transform-none logout-btn cancelBtnText--text font-12 fw-500"
            height="34px"
            min-width="95px"
            color="#F4F6FA"
            elevation="0"
            @click="$emit('valueChanged', false)"
          >
            Cancel
          </v-btn>
          <v-btn
            class="text-transform-none logout-btn textColor--text font-12 fw-500"
            height="34px"
            min-width="95px"
            color="primary"
            elevation="0"
            @click="$emit('confirmClicked')"
          >
            Confirm
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
export default {
  props: {
    value: {
      required: true,
      type: Boolean,
    },
    title: {
      type: String,
      default: "Confirm Logout",
    },
    description: {
      type: String,
      default:
        "Are you sure you want to logout? This will invalidate all of your sessions and you wll need to re-login.",
    },
  },
};
</script>
