<template>
  <div class="text-center">
    <v-dialog
      v-model="dialog"
      width="550"
      content-class="user-permission-dialog"
    >
      <v-card>
        <v-card-title class="font-16 fw-600 greyColor--text logout-title">
          Add new user
          <v-spacer></v-spacer>
          <v-icon class="font-20" @click="dialog = false">mdi-close</v-icon>
        </v-card-title>
        <v-divider></v-divider>

        <v-card-text>
          <v-form ref="newUserFrom" class="pa-3">
            <v-row>
              <v-col
                v-for="(field, i) in fields"
                :key="i"
                cols="6"
                md="6"
                lg="6"
                xl="6"
              >
                <label class="greyColor--text font-12 fw-400">{{
                  field.label
                }}</label>
                <v-text-field
                  v-model="formData[field['name']]"
                  class="login-page font-13 fw-500"
                  :type="field.label"
                  :placeholder="field.placeholder"
                  outlined
                  hide-details="auto"
                  height="42px"
                  dense
                  :rules="
                    field.name != 'middle_name' && field.name != 'email'
                      ? requiredField
                      : field.name == 'email'
                      ? emailRules
                      : []
                  "
                  required
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="12" lg="12" xl="12">
                <label class="greyColor--text font-12 fw-400"
                  >Select Role*</label
                >
                <v-select
                  v-model="formData.role"
                  class="login-page font-13 fw-500"
                  :items="getRoles"
                  append-icon="mdi-chevron-down"
                  item-text="name"
                  item-value="id"
                  placeholder="Select role"
                  color="primary"
                  item-color="primary"
                  dense
                  height="42px"
                  outlined
                  hide-details="auto"
                  :rules="vSelectField"
                  required
                >
                </v-select>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            class="text-transform-none mr-5 mb-5 add-user-btn textColor--text font-12 fw-500"
            height="40px"
            min-width="132px"
            color="primary"
            elevation="0"
            @click="addNewUser"
            :loading="loading"
          >
            Add User
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import ApiService from "@/services/Api";
import { mapGetters } from "vuex";
import {
  showErrorMessage,
  showNotificationBar,
  showSimpleErrorMessage,
} from "@/utils/showError";
export default {
  data() {
    return {
      dialog: false,
      formData: {
        middle_name: "",
      },
      loading: false,
      fields: [
        {
          label: "First Name*",
          name: "first_name",
          placeholder: "Enter first name",
          type: "text",
        },
        {
          label: "Last Name*",
          name: "last_name",
          placeholder: "Enter last name",
          type: "text",
        },
        {
          label: "Middle Name",
          name: "middle_name",
          placeholder: "Enter middle name",
          type: "text",
        },
        {
          label: "Email Address*",
          name: "email",
          placeholder: "Enter email",
          type: "email",
        },
      ],
      requiredField: [
        (v) => !!v || "Field is required",
        (v) =>
          (v || "").length <= 32 || "This field must be 32 characters or less",
      ],
      vSelectField: [(v) => !!v || "Field is required"],
      emailRules: [
        (v) => !!v || "Email is required",
        (v) =>
          /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,})+$/.test(v) ||
          "E-mail must be valid",
      ],
    };
  },
  computed: {
    ...mapGetters(["getRoles"]),
  },
  methods: {
    /**
     * Opens the dialog/modal to add a new user and resets the form fields.
     */
    openDialog() {
      this.dialog = true;
      this.$nextTick(() => {
        this.$refs.newUserFrom.reset();
      });
    },

    /**
     * Adds a new user.
     * Validates form fields and sends a request to the server to add the user.
     */
    addNewUser() {
      if (!this.$refs.newUserFrom.validate()) {
        showSimpleErrorMessage("Please fill required fields correctly!");
        return;
      }
      this.loading = true;
      ApiService.PostRequest("/merchant/add-merchant", {
        first_name: this.formData.first_name,
        last_name: this.formData.last_name,
        middle_name: this.formData.middle_name,
        email: this.formData.email,
        role: this.formData.role,
        company_name: "developers Studio",
        monthly_verification: 10,
      })
        .then((res) => {
          this.dialog = false;
          this.loading = false;
          this.formData = {};
          this.$emit("add-new-user", res.data.result.data);
          showNotificationBar("success", res.data.message);
        })
        .catch((error) => {
          showErrorMessage(error);
          this.dialog = false;
          this.loading = false;
          this.formData = {};
        });
    },
  },
};
</script>

<style>
.logout-title {
  padding: 6px 23px 5px !important;
}
.add-user-btn {
  border-radius: 5px;
}
</style>
