<template>
  <div>
    <v-card class="user-main-settings" color="appBackGround" elevation="0">
      <v-tabs v-model="tab" slider-size="1" background-color="appBackGround">
        <v-tabs-slider class="ml-3" color="primary"></v-tabs-slider>
        <v-tab
          class="font-12 mr-3 fw-500 letter-space text-capitalize"
          v-for="(item, i) in items"
          :key="i"
          active-class="primary--text"
        >
          {{ item.name }}
        </v-tab>
        <v-tabs-items v-model="tab">
          <v-tab-item v-for="(item, i) in items" :key="i">
            <component v-bind:is="item.content" v-bind="item.attrs"></component>
          </v-tab-item>
        </v-tabs-items>
      </v-tabs>
    </v-card>
  </div>
</template>

<script>
import Users from "../user/Users.vue";
import RolePermissions from "../user/RolePermissions.vue";
export default {
  components: { Users, RolePermissions },
  data() {
    return {
      tab: null,
      items: [
        this.hasPermission("view-user-management-merchant")
          ? { name: "All Users", content: "Users" }
          : {},
        this.hasPermission("view-role-permission-merchant")
          ? {
              name: "Role Permissions",
              content: "RolePermissions",
              attrs: { ref: "permissions" },
            }
          : { attrs: { ref: "permissions" } },
      ],
      path: "",
    };
  },
  watch: {
    "$route.path": {
      immediate: true,
      handler(newVal) {
        return (this.path = newVal);
      },
    },

    path: {
      immediate: true,
      handler() {
        if (
          this.hasPermission("view-user-management-merchant") &&
          !this.hasPermission("view-role-permission-merchant")
        ) {
          this.items = [{ name: "All Users", content: "Users" }];
        } else if (
          this.hasPermission("view-role-permission-merchant") &&
          !this.hasPermission("view-user-management-merchant")
        ) {
          this.items = [
            {
              name: "Role Permissions",
              content: "RolePermissions",
            },
          ];
        }
      },
    },
  },
  mounted() {
    Promise.allSettled([
      this.$store.dispatch("GET_PERMISSIONS"),
      this.$store.dispatch("GET_ROLES"),
    ])
      .then(() => {
        if (this.tab === 1) this.$refs.permissions.setFirstSelected();
      })
      .catch((error) => {
        console.log(error);
      });
  },
};
</script>

<style>
.user-content {
  margin-top: 48px !important;
  margin-left: -65px !important;
}
.theme--light.v-divider {
  border-color: #e8ebf6 !important;
}
.user-details-field fieldset {
  border: 1px solid #e1e4ec !important;
  border-radius: 6px;
}
.user-details-field .v-input__slot {
  max-width: 360px !important;
}
.user-switch .v-label {
  font-size: 12px !important;
  color: #8990a2 !important;
}
.v-input--switch.v-input--dense.v-input--switch--inset .v-input--switch__track {
  width: 40px !important;
  height: 23px !important;
}
.v-application--is-ltr .v-input--selection-controls__input {
  margin-right: 0px !important;
}
.user-btns {
  border-radius: 6px !important;
}
.v-tabs-slider-wrapper {
  max-width: 90px !important;
}
.v-tab:before {
  background-color: #fafafa !important;
}
.user-main-settings {
  padding: 0px 12px 0px 12px !important;
}
</style>
