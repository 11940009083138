<template>
  <div class="appBackGround">
    <add-new-role-dialog
      v-if="showNewModal"
      :updateRoleName="updateRoleName"
      ref="newRole"
      @new-role-added="unselectSelectedRole"
    ></add-new-role-dialog>
    <delete-role-modal
      ref="deleteRole"
      @delete-role="deleteRole"
    ></delete-role-modal>
    <v-divider class="ml-3"></v-divider>
    <v-card-title class="greyColor--text user-title-bar font-16 fw-600">
      Roles and Permissions
      <v-spacer></v-spacer>
      <v-btn
        color="primary"
        small
        elevation="0"
        height="30px"
        min-width="136px"
        @click="addNewRole"
        v-if="hasPermission('add-role-merchant')"
        class="textColor--text mr-3 text-capitalize font-12 fw-400"
        ><v-img
          :src="require('/src/assets/icons/plus.svg')"
          class="mr-1"
          min-width="9"
          min-height="9"
        ></v-img>
        Add New Role
      </v-btn>
    </v-card-title>
    <div class="roles-permissions-main d-flex mx-2">
      <div class="left-card">
        <div class="header-title pa-1 pl-6">
          <span class="sec-font sm-f font-12 fw-500 greyColor--text"
            >Select Role</span
          >
        </div>
        <v-list dense class="pa-2 appBackGround permission-title">
          <v-list-item
            class="role-list-items pa-0 secondary--text cursor-pointer"
            v-for="(role, i) in getRoles"
            :key="i"
            :class="{
              'active-list-item-role primary--text': selectedRoleIndex === i,
            }"
          >
            <v-row no-gutters>
              <v-col
                @click="getPerms(role.permissions, role.id, role.name, i)"
                class="d-flex flex-column justify-center pl-2"
              >
                <v-tooltip right v-if="role.name.length > 10">
                  <template v-slot:activator="{ on, attrs }">
                    <span
                      v-bind="attrs"
                      v-on="on"
                      class="sec-font font-12 fw-500 Custom-Color cursor-pointer"
                    >
                      {{
                        role.name.length > 10
                          ? role.name.substring(0, 16) + "..."
                          : role.name
                      }}
                    </span>
                  </template>
                  <span>{{ role.name }}</span>
                </v-tooltip>
                <span
                  v-else
                  class="sec-font font-12 fw-500 Custom-Color cursor-pointer"
                >
                  {{ role.name }}
                </span>
              </v-col>
              <v-col cols="auto" class="pr-2">
                <div class="menu">
                  <v-menu
                    bottom
                    left
                    v-if="
                      hasPermission('edit-role-merchant') ||
                      hasPermission('delete-role-merchant')
                    "
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn dark icon v-bind="attrs" v-on="on">
                        <v-icon class="secondary--text font-16"
                          >mdi-dots-vertical</v-icon
                        >
                      </v-btn>
                    </template>

                    <v-list dense class="font-11">
                      <v-list-item
                        @click="editRole(role)"
                        class="secondary--text"
                        v-if="hasPermission('edit-role-merchant')"
                      >
                        Edit
                      </v-list-item>
                      <v-list-item
                        @click="openDeleteRoleModal(role.id)"
                        class="secondary--text"
                        v-if="hasPermission('delete-role-merchant')"
                      >
                        Delete
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </div>
              </v-col>
            </v-row>
          </v-list-item>
        </v-list>
      </div>
      <div class="right-card">
        <div class="header-title pa-1 pl-6">
          <v-row>
            <v-col>
              <span class="sec-font font-12 fw-500 greyColor--text"
                >Permissions</span
              >
            </v-col>
            <v-col cols="auto" class="d-flex sec-des">
              <v-checkbox
                dense
                class="sec-font check-role-permission ma-auto sec-pos"
                label="Select All"
                color="primary"
                v-model="selectAll"
              ></v-checkbox>
              <v-btn
                v-if="showBtn && hasPermission('edit-role-merchant')"
                text
                plain
                :loading="loading"
                class="sec-font text-capitalize pb-2 cursor-pointer float-right font-12 letter-space primary--text"
                @click="UpdateRolePermissions"
              >
                Save Changes
              </v-btn>
            </v-col>
          </v-row>
        </div>
        <div class="permission-title">
          <v-row class="mx-2 mt-2 mb-5" v-if="showBtn">
            <!-- Dashboard -->
            <v-col class="pa-0 pl-2 mt-3" cols="6" md="6" lg="6" xl="6">
              <p class="sec-font font-12 fw-500 greyColor--text">Dashboard</p>
              <div v-for="(permission, i) in getUserPermissions" :key="i">
                <v-checkbox
                  v-if="permission?.name.includes('dashboard')"
                  class="sec-font check-role-permission sec-inp"
                  :label="
                    permission?.permission_name ===
                    'Activate Deactivate Merchant'
                      ? modifyPermissionLabel(permission?.permission_name)
                      : permission?.permission_name
                  "
                  color="#249DA8"
                  hide-details
                  :ripple="false"
                  v-model="selected"
                  :value="permission.id"
                  :disabled="!hasPermission('edit-role-merchant')"
                ></v-checkbox>
              </div>
            </v-col>
            <!-- Transaction -->
            <v-col class="pa-0 pl-2 mt-3" cols="6" md="6" lg="6" xl="6">
              <p class="sec-font font-12 fw-500 greyColor--text">Transaction</p>
              <div v-for="(permission, i) in getUserPermissions" :key="i">
                <v-checkbox
                  v-if="permission?.name.includes('transaction')"
                  class="sec-font check-role-permission sec-inp my-2"
                  :label="
                    permission?.permission_name ===
                    'Activate Deactivate Merchant'
                      ? modifyPermissionLabel(permission?.permission_name)
                      : permission?.permission_name
                  "
                  color="#249DA8"
                  hide-details
                  :ripple="false"
                  v-model="selected"
                  :value="permission.id"
                  :disabled="!hasPermission('edit-role-merchant')"
                ></v-checkbox>
              </div>
            </v-col>
            <!-- Services -->
            <v-col class="pa-0 pl-2 mt-3" cols="6" md="6" lg="6" xl="6">
              <p class="sec-font font-12 fw-500 greyColor--text">Services</p>
              <div v-for="(permission, i) in getUserPermissions" :key="i">
                <v-checkbox
                  v-if="
                    [
                      'view-one-to-one-demo',
                      'view-one-to-many-demo',
                      'view-age-estimation',
                      'view-face-enrollment',
                      'view-document-ocr',
                      'perform-demo',
                    ].includes(permission?.name)
                  "
                  class="sec-font check-role-permission sec-inp my-2"
                  :label="
                    permission?.permission_name ===
                    'Activate Deactivate Merchant'
                      ? modifyPermissionLabel(permission?.permission_name)
                      : permission?.permission_name
                  "
                  color="#249DA8"
                  hide-details
                  :ripple="false"
                  v-model="selected"
                  :value="permission.id"
                  :disabled="!hasPermission('edit-role-merchant')"
                ></v-checkbox>
              </div>
            </v-col>
            <!-- User Management -->
            <v-col class="pa-0 pl-2 mt-3" cols="6" md="6" lg="6" xl="6">
              <p class="sec-font font-12 fw-500 greyColor--text">
                User Management
              </p>
              <div v-for="(permission, i) in getUserPermissions" :key="i">
                <v-checkbox
                  v-if="
                    [
                      'activate-deactivate-merchant',
                      'edit-merchant',
                      'invite-merchant',
                      'view-user-management-merchant',
                    ].includes(permission?.name)
                  "
                  class="sec-font check-role-permission sec-inp my-2"
                  :label="
                    permission?.permission_name ===
                    'Activate Deactivate Merchant'
                      ? modifyPermissionLabel(permission?.permission_name)
                      : permission?.permission_name
                  "
                  color="#249DA8"
                  hide-details
                  :ripple="false"
                  v-model="selected"
                  :value="permission.id"
                  :disabled="!hasPermission('edit-role-merchant')"
                ></v-checkbox>
              </div>
            </v-col>
            <!-- Role -->
            <v-col class="pa-0 pl-2 mt-3" cols="6" md="6" lg="6" xl="6">
              <p class="sec-font font-12 fw-500 greyColor--text">Role</p>
              <div v-for="(permission, i) in getUserPermissions" :key="i">
                <v-checkbox
                  v-if="permission?.name.includes('role')"
                  class="sec-font check-role-permission sec-inp my-2"
                  :label="
                    permission?.permission_name ===
                    'Activate Deactivate Merchant'
                      ? modifyPermissionLabel(permission?.permission_name)
                      : permission?.permission_name
                  "
                  color="#249DA8"
                  hide-details
                  :ripple="false"
                  v-model="selected"
                  :value="permission.id"
                  :disabled="!hasPermission('edit-role-merchant')"
                ></v-checkbox>
              </div>
            </v-col>
            <!-- Other -->
            <v-col class="pa-0 pl-2 mt-3" cols="6" md="6" lg="6" xl="6">
              <p class="sec-font font-12 fw-500 greyColor--text">Other</p>
              <div v-for="(permission, i) in getUserPermissions" :key="i">
                <v-checkbox
                  v-if="
                    ![
                      'view-one-to-one-demo',
                      'view-one-to-many-demo',
                      'view-age-estimation',
                      'view-face-enrollment',
                      'view-document-ocr',
                      'perform-demo',
                      'activate-deactivate-merchant',
                      'edit-merchant',
                      'invite-merchant',
                      'view-user-management-merchant',
                    ].includes(permission?.name) &&
                    !permission?.name.includes('dashboard') &&
                    !permission?.name.includes('role') &&
                    !permission?.name.includes('transaction')
                  "
                  class="sec-font check-role-permission sec-inp my-2"
                  :label="
                    permission?.permission_name ===
                    'Activate Deactivate Merchant'
                      ? modifyPermissionLabel(permission?.permission_name)
                      : permission?.permission_name
                  "
                  color="#249DA8"
                  hide-details
                  :ripple="false"
                  v-model="selected"
                  :value="permission.id"
                  :disabled="!hasPermission('edit-role-merchant')"
                ></v-checkbox>
              </div>
            </v-col>
          </v-row>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AddNewRoleDialog from "./AddNewRoleDialog.vue";
import DeleteRoleModal from "./DeleteRoleModal.vue";
import { mapGetters } from "vuex";
import { showErrorMessage, showNotificationBar } from "@/utils/showError";
export default {
  components: { AddNewRoleDialog, DeleteRoleModal },
  data() {
    return {
      selected: [],
      selectedRoleIndex: null,
      roles: [],
      newPermissions: [],
      showBtn: false,
      roleId: "",
      roleName: "",
      showNewModal: false,
      updateRoleName: "",
      loading: false,
    };
  },
  computed: {
    ...mapGetters(["getRoles", "getUserPermissions"]),
    selectAll: {
      get: function () {
        return this.getUserPermissions
          ? this.selected.length == this.getUserPermissions.length
          : false;
      },
      set: function (value) {
        var selected = [];

        if (value) {
          this.getUserPermissions.forEach(function (permission) {
            selected.push(permission.id);
          });
        }

        this.selected = selected;
      },
    },
  },
  methods: {
    /**
     * Modifies the permission label.
     * @param {string} permissionName - The permission name to modify.
     * @returns {string} The modified permission label.
     */
    modifyPermissionLabel(permissionName) {
      return permissionName.replace(/(activate) (deactivate)/i, "$1 or $2");
    },

    /**
     * Updates role permissions.
     */
    UpdateRolePermissions() {
      this.loading = true;
      this.$store
        .dispatch("UPDATE_ROLE", {
          data: {
            name: this.roleName,
            permission: this.selected,
          },
          id: this.roleId,
        })
        .then((res) => {
          showNotificationBar("success", res.message);
          this.loading = false;
        })
        .catch((error) => {
          showErrorMessage(error);
          this.loading = false;
        });
    },

    /**
     * Retrieves permissions for a role.
     * @param {Array} items - The list of permissions.
     * @param {number} roleId - The role ID.
     * @param {string} name - The role name.
     * @param {number} index - The index of the selected role.
     */
    getPerms(items, roleId, name, index) {
      this.showBtn = true;
      this.selected = [];
      this.roleId = roleId;
      this.roleName = name;
      this.newPermissions = items;
      this.newPermissions.map((item) => {
        this.selected.push(item.id);
      });
      this.selectedRoleIndex = index;
    },

    /**
     * Unselects the selected role.
     */
    unselectSelectedRole() {
      this.showBtn = false;
      this.selected = [];
      this.roleId = null;
      this.roleName = null;
      this.newPermissions = [];
      this.selectedRoleIndex = null;
    },

    /**
     * Opens the modal to add a new role.
     */
    addNewRole() {
      this.showNewModal = true;
    },

    /**
     * Opens the modal to edit a role.
     * @param {object} role - The role object to be edited.
     */
    editRole(role) {
      this.showNewModal = true;
      this.updateRoleName = role;
    },

    /**
     * Opens the modal to delete a role.
     * @param {number} id - The ID of the role to be deleted.
     */
    openDeleteRoleModal(id) {
      this.$refs.deleteRole.openModal(id);
    },

    /**
     * Deletes the role with the specified ID.
     * @param {number} roleId - The ID of the role to be deleted.
     */
    deleteRole(roleId) {
      this.$store
        .dispatch("DELETE_ROLE", roleId)
        .then((response) => {
          if (roleId === this.roleId) this.unselectSelectedRole();
          showNotificationBar("success", response.data.message);
        })
        .catch((error) => {
          showErrorMessage(error);
        });
    },

    /**
     * Sets the first role as selected if no role is currently selected.
     */
    setFirstSelected() {
      if (
        this.getRoles &&
        this.getRoles[0] &&
        this.selectedRoleIndex === null
      ) {
        this.getPerms(
          this.getRoles[0].permissions,
          this.getRoles[0].id,
          this.getRoles[0].name,
          0
        );
      }
    },
  },
  mounted() {
    this.setFirstSelected();
  },
};
</script>

<style>
.role-list-items:hover .menu {
  display: block !important;
}
.user-title-bar {
  padding: 25px 0px 15px 10px !important;
}
.menu {
  display: none;
}
.roles-permissions-main {
  border: 1px solid #ebedf4;
  border-radius: 8px;
  height: 70vh;
}
.permission-title {
  height: 95%;
  overflow-y: scroll;
}
.left-card {
  width: 221px;
  height: 100%;
  border-right: 1px solid #ebedf4;
}
.header-title {
  border-bottom: 1px solid #ebedf4;
  height: 37px;
}
.right-card {
  width: 100%;
  height: 100%;
}
.list-grid-items {
  display: grid;
  height: 92%;
  overflow-y: auto;
}

.active-list-item-role {
  background: #249da836 !important;
  border-radius: 4px !important;
}
.active-list-item-role::before {
  background-color: transparent !important;
}

/* @media (max-width: 378px) {
  .sm-f{
    font-size: 7px !important;
}
} */
@media (max-width: 541px) {
  .sec-inp {
    margin-top: 40px !important;
    margin-left: -25px;
  }
}

@media (max-width: 400px) {
  .sec-font {
    font-size: 7px !important;
  }
  .check-all .v-input__control .v-input__slot .v-label {
    font-size: 9px !important;
    font-weight: 400;
    color: #99a0b5 !important;
  }
  .check-role-permission .v-input__control .v-input__slot .v-label {
    font-size: 9px !important;
    font-weight: 500;
    color: #8990a2 !important;
    margin-left: 8px;
  }
}
.check-all .v-input__control .v-input__slot .v-label {
  font-size: 12px;
  font-weight: 400;
  color: #99a0b5 !important;
}
.check-role-permission .v-input__control .v-input__slot .v-label {
  font-size: 12px;
  font-weight: 500;
  color: #8990a2 !important;
  margin-left: 8px;
  text-transform: capitalize;
}
.Custom-Color {
  color: black;
}
</style>
