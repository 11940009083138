<template>
  <v-dialog
    v-model="dialog"
    width="485"
    content-class="user-permission-dialog"
    persistent
  >
    <v-card>
      <v-card-title class="font-16 fw-600 greyColor--text logout-title">
        {{ edit ? "Edit role" : "Add new role" }}
        <v-spacer></v-spacer>
        <v-icon class="font-20" @click="closeModal()">mdi-close</v-icon>
      </v-card-title>
      <v-divider></v-divider>

      <v-card-text>
        <v-form ref="newRoleFrom" class="pa-3">
          <v-row>
            <v-col cols="12">
              <label class="greyColor--text font-12 fw-400">Role Name*</label>
              <v-text-field
                v-model="formData.roleName"
                class="login-page font-13 mt-1 fw-500"
                type="text"
                placeholder="Role name"
                outlined
                height="42px"
                hide-details="auto"
                required
                dense
                :rules="roleNameRule"
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row class="pa-0" v-if="!edit">
            <v-col class="d-flex align-center pa-0">
              <span class="font-13 fw-500 greyColor--text">Permissions</span>
            </v-col>
            <v-col cols="auto" class="d-flex align-center pa-0">
              <v-checkbox
                dense
                class="check-role-permission"
                label="Select All"
                color="primary"
                v-model="selectAll"
              ></v-checkbox>
            </v-col>
          </v-row>

          <v-row v-if="!edit">
            <v-col class="pa-0" cols="6">
              <p class="sec-font font-12 fw-500 px-2 greyColor--text">
                Dashboard
              </p>
              <div v-for="(permission, i) in getUserPermissions" :key="i">
                <v-checkbox
                  v-if="permission?.name.includes('dashboard')"
                  class="check-role-permission px-2"
                  :label="permission.permission_name"
                  color="primary"
                  hide-details
                  :ripple="false"
                  v-model="formData.permissionName"
                  :value="permission.id"
                  multiple
                  :rules="required"
                  required
                ></v-checkbox>
              </div>
            </v-col>
            <v-col class="pa-0" cols="6">
              <p class="sec-font font-12 fw-500 px-2 greyColor--text">
                Transaction
              </p>
              <div v-for="(permission, i) in getUserPermissions" :key="i">
                <v-checkbox
                  v-if="permission?.name.includes('transaction')"
                  class="check-role-permission px-2"
                  :label="permission.permission_name"
                  color="primary"
                  hide-details
                  :ripple="false"
                  v-model="formData.permissionName"
                  :value="permission.id"
                  multiple
                  :rules="required"
                  required
                ></v-checkbox>
              </div>
            </v-col>
            <v-col class="pa-0 mt-3" cols="6">
              <p class="sec-font font-12 fw-500 px-2 greyColor--text">
                Services
              </p>
              <div v-for="(permission, i) in getUserPermissions" :key="i">
                <v-checkbox
                  v-if="
                    [
                      'view-one-to-one-demo',
                      'view-one-to-many-demo',
                      'view-age-estimation',
                      'view-face-enrollment',
                      'view-document-ocr',
                      'perform-demo',
                    ].includes(permission?.name)
                  "
                  class="check-role-permission px-2"
                  :label="permission.permission_name"
                  color="primary"
                  hide-details
                  :ripple="false"
                  v-model="formData.permissionName"
                  :value="permission.id"
                  multiple
                  :rules="required"
                  required
                ></v-checkbox>
              </div>
            </v-col>
            <v-col class="pa-0 mt-3" cols="6">
              <p class="sec-font font-12 fw-500 px-2 greyColor--text">
                User Management
              </p>
              <div v-for="(permission, i) in getUserPermissions" :key="i">
                <v-checkbox
                  v-if="
                    [
                      'activate-deactivate-merchant',
                      'edit-merchant',
                      'invite-merchant',
                      'view-user-management-merchant',
                    ].includes(permission?.name)
                  "
                  class="check-role-permission px-2"
                  :label="permission.permission_name"
                  color="primary"
                  hide-details
                  :ripple="false"
                  v-model="formData.permissionName"
                  :value="permission.id"
                  multiple
                  :rules="required"
                  required
                ></v-checkbox>
              </div>
            </v-col>
            <v-col class="pa-0 mt-3" cols="6">
              <p class="sec-font font-12 fw-500 px-2 greyColor--text">Role</p>
              <div v-for="(permission, i) in getUserPermissions" :key="i">
                <v-checkbox
                  v-if="permission?.name.includes('role')"
                  class="check-role-permission px-2"
                  :label="permission.permission_name"
                  color="primary"
                  hide-details
                  :ripple="false"
                  v-model="formData.permissionName"
                  :value="permission.id"
                  multiple
                  :rules="required"
                  required
                ></v-checkbox>
              </div>
            </v-col>
            <v-col class="pa-0 mt-3" cols="6">
              <p class="sec-font font-12 fw-500 px-2 greyColor--text">Other</p>
              <div v-for="(permission, i) in getUserPermissions" :key="i">
                <v-checkbox
                  v-if="
                    ![
                      'view-one-to-one-demo',
                      'view-one-to-many-demo',
                      'view-age-estimation',
                      'view-face-enrollment',
                      'view-document-ocr',
                      'perform-demo',
                      'activate-deactivate-merchant',
                      'edit-merchant',
                      'invite-merchant',
                      'view-user-management-merchant',
                    ].includes(permission?.name) &&
                    !permission?.name.includes('dashboard') &&
                    !permission?.name.includes('role') &&
                    !permission?.name.includes('transaction')
                  "
                  class="check-role-permission px-2"
                  :label="permission.permission_name"
                  color="primary"
                  hide-details
                  :ripple="false"
                  v-model="formData.permissionName"
                  :value="permission.id"
                  multiple
                  :rules="required"
                  required
                ></v-checkbox>
              </div>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          class="text-transform-none mr-5 mb-5 add-user-btn textColor--text font-12 fw-500"
          height="40px"
          min-width="132px"
          color="primary"
          elevation="0"
          :loading="loading"
          @click="onFormSubmit"
        >
          {{ edit ? "Update Role" : "Add Role" }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapGetters } from "vuex";
import {
  showErrorMessage,
  showSimpleErrorMessage,
  showNotificationBar,
} from "@/utils/showError";
export default {
  props: ["updateRoleName"],
  data() {
    return {
      dialog: false,
      loading: false,
      formData: {
        roleName: "",
        permissionName: [],
      },
      permissions: [],
      isLoading: false,
      roleId: "",
      edit: false,
      required: [(v) => !!v || "Field is required"],
      roleNameRule: [
        (v) => !!v || "Field is required",
        (v) =>
          (v || "").length <= 32 || "Role name must be 32 characters or less",
      ],
    };
  },
  computed: {
    ...mapGetters(["getUserPermissions"]),
    selectAll: {
      get: function () {
        return this.getUserPermissions
          ? this.formData.permissionName.length ==
              this.getUserPermissions.length
          : false;
      },
      set: function (value) {
        var selected = [];

        if (value) {
          this.getUserPermissions.forEach(function (permission) {
            selected.push(permission.id);
          });
        }

        this.formData.permissionName = selected;
      },
    },
  },
  mounted() {
    if (this.updateRoleName) {
      this.showUpdateModal(this.updateRoleName);
    } else {
      this.showNewModal();
    }
  },
  methods: {
    /**
     * Closes the transaction history modal dialog and resets form data.
     */
    closeModal() {
      this.dialog = false;
      this.formData = {
        roleName: "",
        permissionName: [],
      };
      this.$parent.showNewModal = false;
    },

    /**
     * Handles form submission. Calls either the updateRole() or addNewRole() method based on the 'edit' flag.
     */
    onFormSubmit() {
      if (this.edit == true) {
        this.updateRole();
      } else {
        this.addNewRole();
      }
    },

    /**
     * Updates an existing role with the provided data.
     */
    updateRole() {
      if (!this.$refs.newRoleFrom.validate()) {
        showSimpleErrorMessage("Please fill required fields correctly!");
        return;
      }
      this.loading = true;
      this.$store
        .dispatch("UPDATE_ROLE", {
          data: {
            name: this.formData.roleName,
            permission: this.rolePermissions,
          },
          id: this.roleId,
        })
        .then((res) => {
          showNotificationBar("success", res.message);
          this.loading = false;
          this.closeModal();
          this.$refs.newRoleFrom.reset();
          window.location.reload();
        })
        .catch((error) => {
          this.loading = false;
          showErrorMessage(error);
        });
    },

    /**
     * Adds a new role with the provided data.
     */
    addNewRole() {
      if (!this.$refs.newRoleFrom.validate()) {
        showSimpleErrorMessage("Please fill required fields correctly!");
        return;
      }
      this.loading = true;
      this.$store
        .dispatch("ADD_ROLE", {
          name: this.formData.roleName,
          permission: this.formData.permissionName,
        })
        .then((res) => {
          showNotificationBar("success", res.message);
          this.loading = false;
          this.$emit("new-role-added");
          this.$refs.newRoleFrom.reset();
          this.closeModal();
        })
        .catch((error) => {
          showErrorMessage(error);
          this.loading = false;
        });
    },

    /**
     * Opens the modal to add a new role.
     */
    showNewModal() {
      this.formData.roleName = "";
      this.edit = false;
      this.dialog = true;
    },

    /**
     * Opens the modal to update an existing role.
     * @param {Object} role - The role object to be updated.
     */
    showUpdateModal(role) {
      this.formData.roleName = role.name;
      this.roleId = role.id;
      this.dialog = true;
      this.edit = true;
    },
  },
};
</script>

<style>
.logout-title {
  padding: 6px 23px 5px !important;
}
.add-user-btn {
  border-radius: 5px;
}
</style>
