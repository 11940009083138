<template>
  <div class="appBackGround">
    <add-new-user-modal
      ref="newUser"
      @add-new-user="addUser"
    ></add-new-user-modal>
    <edit-user-modal
      @editted-user="updateUser"
      ref="editUser"
    ></edit-user-modal>
    <confirm-popup
      :value="value"
      v-if="hasPermission('activate-deactivate-merchant')"
      :title="`Confirm ${selectedItem.is_blocked == 0 ? 'Block' : 'un-block'}`"
      :description="`Are you sure you want to ${
        selectedItem.is_blocked == 0 ? 'Block' : 'Un-block'
      }?`"
      @valueChanged="value = $event"
      @confirmClicked="toggleUserStatus"
    ></confirm-popup>
    <v-divider class="ml-3"></v-divider>
    <v-card-title class="greyColor--text user-title-bar font-16 fw-600">
      All Users
      <v-spacer></v-spacer>
      <v-btn
        color="primary"
        small
        elevation="0"
        height="30px"
        min-width="136px"
        class="textColor--text mr-3 text-capitalize font-12 fw-400"
        @click="newUserModal"
        v-if="hasPermission('invite-merchant')"
        ><v-img
          :src="require('/src/assets/icons/plus.svg')"
          class="mr-1"
          min-width="9"
          min-height="9"
        ></v-img>
        Add New Users
      </v-btn>
    </v-card-title>
    <DataTable
      :customHeaders="headers"
      :customSlots="['role', 'updated_at', 'is_active', 'is_blocked', 'action']"
      :data="items"
      :loading="isLoading"
      :roles="roles"
    >
      <template v-slot:role="{ item }">
        <span>{{
          Array.isArray(item.roles) && item.roles.length > 0
            ? item.roles[0].name
            : ""
        }}</span>
      </template>
      <template v-slot:updated_at="{ item }">
        {{ item.updated_at | convertUTCtoLocalTime }}
      </template>
      <template v-slot:is_active="{ item }">
        <v-chip
          :class="!!item.is_active ? 'status-active' : 'status-in-active'"
        >
          {{ item.is_active ? "Active" : "In-active" }}
        </v-chip>
      </template>
      <template v-slot:is_blocked="{ item }">
        <v-chip
          :class="!!item.is_blocked ? 'status-in-active' : 'status-active'"
        >
          {{ item.is_blocked ? "blocked" : "un-blocked" }}
        </v-chip>
      </template>
      <template v-slot:action="{ item }">
        <v-tooltip top v-for="i in 2" :key="i">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-if="
                i === 1
                  ? hasPermission('activate-deactivate-merchant')
                  : hasPermission('edit-merchant')
              "
              icon
              v-bind="attrs"
              v-on="on"
              @click="i === 1 ? openConfirmPopup(item) : editUser(item)"
              class="action-btn-container"
              :color="item.is_blocked ? '#ff784b' : 'primary'"
              ><v-icon class="font-20"
                >mdi-{{
                  i === 1
                    ? item.is_blocked == 0
                      ? "toggle-switch"
                      : "toggle-switch-off"
                    : "square-edit-outline"
                }}</v-icon
              ></v-btn
            >
          </template>
          <span>{{
            i === 1 ? (item.is_blocked == 0 ? "block" : "un-block") : "Edit"
          }}</span>
        </v-tooltip>
        <v-btn
          icon
          @click="sendMail(item)"
          color="primary"
          v-if="item.is_active === false"
          :disabled="item.disable"
        >
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon v-bind="attrs" v-on="on" class="font-20"
                >mdi-email</v-icon
              >
            </template>
            <span>Mail</span>
          </v-tooltip>
        </v-btn>
      </template>
      <template v-slot:body-prepend>
        <tr>
          <td>
            <v-text-field
              v-model="filters.first_name"
              type="text"
              placeholder="Search"
              class="input-field-border font-12 fw-500 mt-2 mb-2"
              hide-details
              dense
              outlined
            >
              <template slot="prepend-inner">
                <img
                  width="15"
                  :src="require('/src/assets/icons/MagnifyingGlass.svg')"
                  class="mr-1"
                />
              </template>
            </v-text-field>
          </td>
          <td>
            <v-text-field
              v-model="filters.last_name"
              type="text"
              placeholder="Search"
              class="input-field-border font-12 fw-500 mt-2 mb-2"
              hide-details
              dense
              outlined
            >
              <template slot="prepend-inner">
                <img
                  width="15"
                  :src="require('/src/assets/icons/MagnifyingGlass.svg')"
                  class="mr-1"
                />
              </template>
            </v-text-field>
          </td>
          <td>
            <v-text-field
              v-model="filters.email"
              type="email"
              placeholder="Search"
              class="input-field-border font-12 fw-500 mt-2 mb-2"
              hide-details
              dense
              outlined
            >
              <template slot="prepend-inner">
                <img
                  width="15"
                  :src="require('/src/assets/icons/MagnifyingGlass.svg')"
                  class="mr-1"
                />
              </template>
            </v-text-field>
          </td>
          <td>
            <v-select
              v-model="filters.role"
              :items="getRolesWithOnlyName"
              item-value="value"
              type="select"
              height="32px"
              clearable
              placeholder="Select"
              class="input-field-border font-12 fw-500 mt-2 mb-2"
              :append-icon="filters.role == null ? 'mdi-chevron-down' : ''"
              hide-details
              dense
              outlined
            >
            </v-select>
          </td>
          <td>
            <v-menu
              v-model="menu"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="dates"
                  type="select"
                  placeholder="Select"
                  class="input-field-border font-12 fw-500 mt-2 mb-2"
                  append-icon="mdi-chevron-down"
                  hide-details
                  dense
                  outlined
                  v-bind="attrs"
                  v-on="on"
                >
                  <template slot="prepend-inner">
                    <img
                      width="15"
                      :src="require('/src/assets/icons/CalendarBlank.svg')"
                      class="mr-1"
                    />
                  </template>
                </v-text-field>
              </template>
              <v-date-picker
                flat
                no-title
                range
                color="#249DA8"
                v-model="dates"
                class="date-picker"
              >
                <v-spacer></v-spacer>
                <div class="mb-3">
                  <span
                    class="font-12 pr-2 secondary--text fw-600 text-transform-none cursor-pointer"
                    @click="clearSearch"
                    >Clear</span
                  >
                  <span
                    class="font-12 pr-4 secondary--text fw-600 text-transform-none cursor-pointer"
                    @click="getDataByDate"
                    >Apply</span
                  >
                </div>
              </v-date-picker>
            </v-menu>
          </td>
          <td>
            <v-select
              v-model="filters.status"
              item-value="value"
              type="select"
              height="32px"
              :items="statusItems"
              clearable
              placeholder="Select"
              class="input-field-border font-12 fw-500 mt-2 mb-2"
              :append-icon="filters.status == null ? 'mdi-chevron-down' : ''"
              hide-details
              dense
              outlined
            >
            </v-select>
          </td>
          <!-- just for border bottom -->
          <td></td>
          <td></td>
        </tr>
      </template>
    </DataTable>
    <pagination
      :user="true"
      :itemsPerPage="paginate.itemsPerPage"
      :page="paginate.page"
      :pageCount="lastPage"
      :totalCount="total"
      @next-page-change="paginate.page = $event"
      @prev-page-change="paginate.page = $event"
      @page-number-changed="paginate.page = $event"
      @items-per-page-change="paginate.itemsPerPage = $event"
    ></pagination>
  </div>
</template>

<script>
import DataTable from "../common/DataTable.vue";
import Pagination from "../common/Pagination.vue";
import AddNewUserModal from "./AddNewUserModal.vue";
import EditUserModal from "./EditUserModal.vue";
import ApiService from "@/services/Api";
import ConfirmPopup from "@/components/common/ConfirmPopup.vue";
import { showErrorMessage, showNotificationBar } from "@/utils/showError";
import { mapGetters } from "vuex";
export default {
  components: {
    DataTable,
    Pagination,
    AddNewUserModal,
    EditUserModal,
    ConfirmPopup,
  },
  data() {
    return {
      isLoading: false,
      headers: [
        { text: "First Name", value: "first_name" },
        { text: "Last Name", value: "last_name" },
        { text: "Email", value: "email" },
        { text: "Role", value: "role" },
        { text: "Updated At", value: "updated_at" },
        { text: "Status", value: "is_active" },
        { text: "Block Status", value: "is_blocked" },
        { text: "Actions", value: "action", width: "140px" },
      ],
      menu: false,
      statusItems: [
        { text: "All", value: "all" },
        { text: "Active", value: "true" },
        { text: "In-active", value: "false" },
      ],
      dates: [],
      selectedItem: {},
      value: false,
      items: [],
      roles: [],
      paginate: {
        page: 1,
        itemsPerPage: 10,
      },
      filters: {
        first_name: "",
        last_name: "",
        email: "",
        role: "",
        update_start_date: "",
        update_end_date: "",
        status: "all",
      },
      total: null,
      lastPage: null,
    };
  },
  computed: {
    ...mapGetters(["getRolesWithOnlyName"]),
  },
  methods: {
    /**
     * Opens the modal to edit the details of the specified user.
     * @param {object} user - The user object containing details to be edited.
     */
    editUser(user) {
      this.$refs.editUser.showModal(user);
    },

    /**
     * Adds a new user to the list and updates the total count.
     * @param {object} user - The user object to be added.
     */
    addUser(user) {
      this.items.unshift(user);
      this.total++;
    },

    /**
     * Resets all filters to their default values.
     */
    resetFilters() {
      this.filters = {
        first_name: "",
        last_name: "",
        email: "",
        role: "",
        update_start_date: "",
        update_end_date: "",
        status: "all",
      };
    },

    /**
     * Opens a confirmation popup for the specified item.
     * @param {object} item - The item for which the confirmation popup is opened.
     */
    openConfirmPopup(item) {
      this.selectedItem = item;
      this.value = true;
    },

    /**
     * Retrieves all users based on applied filters.
     */
    getAllUsers() {
      this.isLoading = true;
      ApiService.PostRequest("/merchant/sub-merchant", {
        pagination: this.paginate.itemsPerPage,
        page: this.paginate.page,
        first_name: this.filters.first_name,
        last_name: this.filters.last_name,
        email: this.filters.email,
        role: this.filters.role,
        update_start_date: this.filters.update_start_date,
        update_end_date: this.filters.update_end_date,
        status: this.filters.status,
      })
        .then((res) => {
          this.isLoading = false;
          this.total = res.data.result.total;
          this.lastPage = res.data.result.last_page;
          this.items = res.data.result.data;
          this.isLoading = false;
        })
        .catch((err) => {
          showErrorMessage(err);
          if (err.response.status === 401) {
            this.$store.dispatch("logout");
            this.$store.dispatch("SET_LOGOUT");
            this.$router.push("/");
          }
        });
    },

    /**
     * Opens the new user modal.
     */
    newUserModal() {
      this.$refs.newUser.openDialog();
    },

    /**
     * Deletes a user with the specified ID.
     * @param {string} id - The ID of the user to be deleted.
     */
    deleteUser(id) {
      ApiService.PostRequest(`/merchant/sub-merchant-delete/${id}`)
        .then(() => {
          showNotificationBar("success", "User has been deleted successfully.");
        })
        .catch((error) => {
          showErrorMessage(error);
        });
    },

    /**
     * Updates the user with new data.
     * @param {object} user - The updated user object.
     */
    updateUser(user) {
      const foundIndex = this.items.findIndex((item) => item.id == user.id);
      if (foundIndex > -1) {
        this.$set(this.items, foundIndex, user);
        showNotificationBar("success", "User has been updated successfully");
      }
    },

    /**
     * Toggles the status of the selected user.
     */
    toggleUserStatus() {
      const item = { ...this.selectedItem };
      const active = item.is_blocked == 0 ? 1 : 0;
      ApiService.PostRequest("/merchant/status-update", {
        is_block: active,
        merchant_id: item.id,
      })
        .then((res) => {
          showNotificationBar("success", res.data.message);
          item.is_blocked = active;
          const foundIndex = this.items.findIndex((deo) => deo.id == item.id);
          if (foundIndex > -1) this.$set(this.items, foundIndex, item);
          this.value = false;
        })
        .catch((error) => {
          showErrorMessage(error);
        });
    },

    /**
     * Updates the filters based on the selected date range.
     */
    getDataByDate() {
      this.menu = false;
      this.filters = {
        ...this.filters,
        update_end_date: this.dates[1],
        update_start_date: this.dates[0],
      };
    },

    /**
     * Clears the search filters and date range.
     */
    clearSearch() {
      this.menu = false;
      this.dates = [];
      this.filters = {
        ...this.filters,
        update_end_date: "",
        update_start_date: "",
      };
    },

    /**
     * Sends an email to the merchant identified by the given item.
     * @param {object} item - The merchant item to send the email to.
     */
    sendMail(item) {
      this.$set(item, "disable", true);
      ApiService.PostRequest("merchant/send-email", {
        merchant_id: item.id,
      })
        .then((res) => {
          item.disable = false;
          showNotificationBar("success", res.data.message);
        })
        .catch((error) => {
          item.disable = false;
          showErrorMessage(error);
        });
    },
  },
  watch: {
    paginate: {
      handler() {
        this.getAllUsers();
      },
      deep: true,
    },
    filters: {
      handler() {
        const page = this.paginate.page;
        this.paginate.page = 1;
        if (page === 1) this.getAllUsers();
      },
      deep: true,
    },
  },
  mounted() {
    this.getAllUsers();
  },
};
</script>

<style>
.user-title-bar {
  padding: 25px 0px 15px 10px !important;
}
.action-btn-container.v-btn .v-btn__content .action-icons {
  font-size: 19px !important;
}
.action-btn-container::before {
  background-color: transparent !important;
}
.status-active {
  font-size: 12px !important;
  font-weight: 500;
  color: #4fb0b9 !important;
  background-color: #edf4f5 !important;
  height: 20px !important;
  border-radius: 4px !important;
}
.status-in-active {
  font-size: 12px !important;
  font-weight: 500;
  color: #ff784b !important;
  background-color: #faf2f0 !important;
  height: 20px !important;
  border-radius: 4px !important;
}
</style>
