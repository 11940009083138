<template>
  <div
    :class="
      user
        ? 'custom-pagination-container mt-2'
        : 'custom-pagination-container pa-3'
    "
    v-if="totalCount > 0"
  >
    <div class="footer-pagination" cols="12">
      <div
        :class="
          user
            ? 'user-footer-pagination-para pagination-enteries font-11 fw-400 secondary--text'
            : 'footer-pagination-para pagination-enteries font-11 fw-400 secondary--text'
        "
        v-if="showDataDetails"
      >
        {{ pagination }}
      </div>
      <v-spacer></v-spacer>
      <div
        class="footer-pagination-items"
        :class="user ? 'user-footer-pagination-items justify-end' : position"
      >
        <!-- Previous button -->
        <v-btn
          :disabled="this.page == 1"
          @click="prevPageButton"
          class="prev-btn"
          elevation="0"
          ><v-icon class="pagination-btn-icon font-15"
            >mdi-chevron-left</v-icon
          ></v-btn
        >
        <v-pagination
          :value="page"
          @input="$emit('page-number-changed', $event)"
          @next="$emit('next-page-changed')"
          @previous="$emit('previous-page-changed')"
          :total-visible="7"
          :length="pageCount"
          color="#FAFAFA"
          class="custom-pagination"
        ></v-pagination>
        <!-- Next button -->
        <v-btn
          :disabled="this.page === pageCount || totalCount == 0"
          @click="nextPageButton"
          class="next-btn"
          elevation="0"
          ><v-icon class="pagination-btn-icon font-15"
            >mdi-chevron-right</v-icon
          ></v-btn
        >
      </div>
    </div>
  </div>
</template>

<script>
/**
 * Description: This pagination component is used throughout the application to maintain the styling. The component 
   can show current page text based on prop, page buttons and items per page textfield.
 */

export default {
  name: "Pagination",
  props: {
    page: {
      type: Number,
      default: 0,
    },
    pageCount: {
      type: Number,
      default: 0,
    },
    itemsPerPage: {
      type: Number,
      default: 0,
    },
    totalCount: {
      type: Number,
      default: 0,
    },
    showDataDetails: {
      type: Boolean,
      default: true,
    },
    showCustomPageItems: {
      type: Boolean,
      default: true,
    },
    position: {
      type: String,
      default: "justify-end",
    },
    user: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    pagination() {
      return this.totalCount > 0
        ? "Showing " +
            (this.page * this.itemsPerPage - this.itemsPerPage + 1) +
            " to " +
            (this.page * this.itemsPerPage <= this.totalCount
              ? this.page * this.itemsPerPage
              : this.totalCount) +
            " of " +
            this.totalCount
        : "No Records to show";
    },
  },
  methods: {
    /**
     * handle items per page property. Change data when items per page changes
     */
    itemsPage(event) {
      if (event) {
        this.itemPerPage = parseInt(event);
        this.$emit("items-per-page-change", parseInt(event));
      }
    },

    /**
     * previous button click handler
     */
    prevPageButton() {
      let prePage = this.page - 1;
      if (this.page > 1) {
        this.$emit("prev-page-change", prePage);
      }
    },
    /**
     * next button click handler
     */
    nextPageButton() {
      let newPage = this.page + 1;
      if (this.page < this.pageCount) {
        this.$emit("next-page-change", newPage);
      }
    },
  },
};
</script>
<style>
/* Table Footer pagination */

.footer-pagination-para {
  display: flex;
  width: 25%;
  margin-left: 20px;
}
.user-footer-pagination-para {
  display: flex;
  width: 25%;
  margin-left: 10px;
}

.footer-pagination-items {
  display: flex;
  align-items: center;
  width: 100%;
  margin-right: 16px;
}
.user-footer-pagination-items {
  display: flex;
  align-items: center;
  width: 100%;
  margin-right: 0px;
}

.pagination-enteries {
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #484848;
}

.pagination-text {
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 12px;
  padding: 0px 5px !important;
  line-height: 16px;
  color: #99a0b5;
}

.search-pagination {
  width: 81px !important;
  margin: 0px 10px !important;
  font-family: "Inter", sans-serif;
  font-size: 12px;
  font-weight: 400;
  flex: 0 0 auto !important;
}

.footer-pagination {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Pagination end */

.v-pagination li:nth-child(1),
.v-pagination li:last-child {
  display: none !important;
}

.next-btn,
.prev-btn {
  background-color: #e5e5e5;
  border-radius: 4px;
  height: 18px !important;
  width: 18px !important;
  min-height: 18px !important;
  min-width: 18px !important;
  padding: 0px !important;
}
.pagination-btn-icon {
  color: #99a0b5 !important;
}
.custom-pagination .v-pagination__navigation {
  box-shadow: none !important;
}
.custom-pagination .v-pagination__item {
  box-shadow: none !important;
  font-family: "Inter", sans-serif;
  margin: 0px 0px !important;
  padding: 0px !important;
  font-size: 9px !important;
  color: #8990a2 !important;
  background-color: #fafafa !important;
}
.custom-pagination .v-pagination__item--active {
  color: #292b30 !important;
  font-size: 11px !important;
  font-weight: 600 !important;
}
</style>
