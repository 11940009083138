<template>
  <div class="text-center">
    <v-dialog v-model="dialog" width="410">
      <v-card>
        <v-card-title class="font-16 fw-600 greyColor--text logout-title">
          Delete Role
        </v-card-title>
        <v-divider></v-divider>

        <v-card-text class="font-12 fw-500 secondary--text mt-3">
          Are you sure you want to delete this role?
        </v-card-text>

        <v-card-actions class="pb-7">
          <v-spacer></v-spacer>
          <v-btn
            class="text-transform-none logout-btn cancelBtnText--text font-12 fw-500"
            height="34px"
            min-width="95px"
            color="#F4F6FA"
            elevation="0"
            @click="dialog = false"
          >
            Cancel
          </v-btn>
          <v-btn
            class="text-transform-none logout-btn textColor--text font-12 fw-500"
            height="34px"
            min-width="95px"
            color="primary"
            elevation="0"
            @click="confirmDelete(roleId)"
          >
            Confirm
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
export default {
  data() {
    return {
      dialog: false,
      roleId: "",
    };
  },
  methods: {
    /**
     * Emits the roleId to be deleted and closes the dialog.
     * @param {string} roleId - The ID of the role to be deleted.
     */
    confirmDelete(roleId) {
      this.$emit("delete-role", roleId);
      this.dialog = false;
    },

    /**
     * Sets the roleId and opens the dialog.
     * @param {string} id - The ID of the role.
     */
    openModal(id) {
      this.roleId = id;
      this.dialog = true;
    },
  },
};
</script>

<style>
.logout-title {
  padding: 6px 23px 5px !important;
}
.logout-btn {
  border-radius: 5px;
}
</style>
